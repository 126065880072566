/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:29 AM */

@font-face {
	font-family: 'Bebas Neue';
	src: url('/assets/fonts/BebasNeue/BebasNeueRegular.eot');
	src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
		url('/assets/fonts/BebasNeue/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/BebasNeue/BebasNeueRegular.woff') format('woff'),
		url('/assets/fonts/BebasNeue/BebasNeueRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('/assets/fonts/BebasNeue/BebasNeueBold.eot');
	src: local('Bebas Neue Bold'), local('BebasNeueBold'),
		url('/assets/fonts/BebasNeue/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/BebasNeue/BebasNeueBold.woff') format('woff'),
		url('/assets/fonts/BebasNeue/BebasNeueBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('/assets/fonts/BebasNeue/BebasNeueLight.eot');
	src: local('Bebas Neue Light'), local('BebasNeueLight'),
		url('/assets/fonts/BebasNeue/BebasNeueLight.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/BebasNeue/BebasNeueLight.woff') format('woff'),
		url('/assets/fonts/BebasNeue/BebasNeueLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('/assets/fonts/BebasNeue/BebasNeue-Thin.eot');
	src: local('Bebas Neue Thin'), local('BebasNeue-Thin'),
		url('/assets/fonts/BebasNeue/BebasNeue-Thin.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/BebasNeue/BebasNeue-Thin.woff') format('woff'),
		url('/assets/fonts/BebasNeue/BebasNeue-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Book';
	src: url('/assets/fonts/BebasNeue/BebasNeueBook.eot');
	src: local('Bebas Neue Book'), local('BebasNeueBook'),
		url('/assets/fonts/BebasNeue/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/BebasNeue/BebasNeueBook.woff') format('woff'),
		url('/assets/fonts/BebasNeue/BebasNeueBook.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
